import React from "react";
import './Style.css';
import load from './load.gif';

export default function Main() {
    let [state, setState] = React.useState({prefix: "", wallet: null, "disabled": false, "progress": "", blur:true});

    function handleChange(event) {
        setState(prevState => {
            state = {
                ...prevState,
                [event.target.name]: event.target.value
            }
            return state;
        })
    }

    function getOnmessage() {
        return function (event) {
            if (event.data.type === "key") {
                setState(prevState => {
                    state = {
                        ...prevState,
                        "wallet": {
                            "address": event.data.address,
                            "mnemonic": event.data.mnemonic
                        },
                        "disabled": false,
                        "progress": 0
                    }
                    return state;
                })
            }
            if (event.data.type === "progress") {
                console.log("")
                setState(prevState => {
                    state = {
                        ...prevState,
                        "progress": event.data.count,
                    }
                    return state;
                })
            }
        }
    }

    function toggleBlur() {
        setState(prevState => {
            state = {
                ...prevState,
                blur: !prevState.blur
            }
            return state;
        })
    }

    function gen() {
        console.log(state.prefix);
        setState(prevState => {
            state = {
                ...prevState,
                "disabled": true,
                "progress": 1,
                "wallet": null
            }
            return state;
        })
        let worker = new Worker("main.js");
        worker.postMessage({"prefix": state.prefix});
        worker.onmessage = getOnmessage();
    }

    return (<main>
        <div className="warning"><strong>Warning:</strong> Seed phrase is a group of words that allow access to a
            cryptocurrency wallet.
            Be careful where you paste them! We do not record them, generation is done on the client side.
        </div>

        <div className="gen-form">

            <div  className="generate-container">
                <span className="OX">0x</span>
                <input className="prefix" id="gen-prefix" type="text" placeholder="prefix" name="prefix" onChange={handleChange}
                   value={state.prefix}/>
            </div>

            <button className="generate-button" type="button" onClick={gen} disabled={state.disabled}>Generate Mnemonic</button>

            {state.wallet &&
            <div>{state.wallet.address}</div>}
            {state.wallet &&
                <div className={state.blur ? 'blur':''}>{state.wallet.mnemonic}
                    <button className="copy-show" onClick={() => { navigator.clipboard.writeText(state.wallet.mnemonic)}}>Copy</button>
                    <button className="copy-show"  onClick={toggleBlur} >{state.blur ? 'Show':'Hide'}</button>
                </div>
            }

            {state.progress > 0 &&
            <div> Progress: {state.progress} mnemonics generated
                <br/>
                <img src={load}/>
            </div>
            }
            <hr/>
            <div className="info">
                <div className="info-box">
                    <h2>Vanity addressed</h2>
                    Vanity addresses are cryptocurrency addresses made in accordance with a number of specifications provided by users
                     in an effort to increase their level of personalization without compromising the security.
                </div>
                <div className="info-box">
                    <h2></h2>
                    The app will generate a mnemonics(also called seed phrase) in such a way that the first address will be respect specification.
                    The mnemonic is compatible with metamask and other EVM wallets. All the code is running in browser.
                    No backend or persistence is involved, once you closed the tab everything is gone
                </div>
            </div>
        </div>
    </main>)
}